#block-views-touren-in-sevilla-block {
  @extend %clearfix;
  padding: 2em 0;
  text-align: center;
  .block__content {
    @extend .full-padding; } }

@include breakpoint($tab) {
  #block-views-touren-in-sevilla-block {
    background: url(../images/bg-map-v01.jpg) center top transparent no-repeat;
    background-size: cover;
    .views-row {
      @include gallery(1 of 2);
      margin-bottom: 2em; } } }

@include breakpoint($desk);
