body {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  line-height: 1.5;
  color: $text_medium; }

h1, h2, h3, h4, h5 ,h6 {
  margin: 0;
  font-family: 'Fjalla One', sans-serif;
  font-weight: 400;
  text-align: left;
  color: $color_main_dark; }
h1 {
  font-size: 1.8em;
  margin-bottom: 0; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.3em; }


h4, h5, h6 {
  font-size: 1.1em; }

p {
  margin: 0 0 .5em 0; }

strong {
  font-weight: 700; }

a {
  color: $link_main;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $link_main_hover; } }
