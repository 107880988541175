.l-region--sidebar-first,
.l-region--sidebar-second {
  font-size: .8em; }

.l-region--sidebar-second {
  .block {
    @include clearfix;
    padding-bottom: 1em;
    margin-bottom: 1em; } }

@include breakpoint($tab) {
  .l-region--sidebar-first {
    padding-top: 60px; } }
