#block-search-form {
  .form-item-search-block-form,
  .form-actions {
    display: inline-block;
    vertical-align: top;

    .form-submit {
      margin: 0;
      background-color: transparent;
      border: 1px solid #ddd;
      padding: 0 .4em;
      box-shadow: none;
      &:hover {
        border: 1px solid #fff; } }

    .form-item {
      margin-bottom: 0; }

    .form-text {
      color: #444; }

    input {
      height: 1.5em; } } }

@include breakpoint($desk) {
  #block-search-form {
    text-align: right; } }
