.node--article--teaser {
  > img {
    float: left;
    margin: 0 1em 1em 0; }
  .field-name-node-link {
    float: right;
    font-size: .9em;
    color: $color_main; } }


.node--article--full {
  .field-name-field-image {
    margin-bottom: 2rem; }
  .field-name-body {
    margin-bottom: 2rem; }
  .field-name-field-bildergallerie {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > a {
      display: inline-block;
      margin: 1rem;
      flex: none; } } }
