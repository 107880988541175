*, *:before, *:after {
  box-sizing: border-box; }

/**
* 1. Set default font family to sans-serif.
* 2. Prevent iOS and IE text size adjust after device orientation
*    change, without disabling
*    user zoom.
*/
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
* Remove default margin.
*/
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
* Correct `block` display not defined for any HTML5 element in IE 8/9.
* Correct `block` display not defined for `details` or `summary` in IE 10/11
* and Firefox.
* Correct `block` display not defined for `main` in IE 11.
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
* 1. Correct `inline-block` display not defined in IE 8/9.
* 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
*/
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
* Prevent modern browsers from displaying `audio` without controls.
* Remove excess height in iOS 5 devices.
*/
audio:not([controls]) {
  display: none;
  height: 0; }

/**
   * Address `[hidden]` styling not present in IE 8/9/10.
   */
[hidden] {
  display: none; }

/**
* Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
*/
template {
  display: none; }

/* Links
   ========================================================================== */
/**
   * Remove the gray background color from active links in IE 10.
   */
a {
  background-color: transparent; }

/**
* Improve readability of focused elements when they are also in an
* active/hover state.
*/
a:active,
a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
* Address styling not present in IE 8/9/10/11, Safari, and Chrome.
*/
abbr[title] {
  border-bottom: 1px dotted; }

/**
* Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
*/
b,
strong {
  font-weight: bold; }

/**
* Address styling not present in Safari and Chrome.
*/
dfn {
  font-style: italic; }

/**
* Address variable `h1` font-size and margin within `section` and `article`
* contexts in Firefox 4+, Safari, and Chrome.
*/
h1 {
  font-size: 2em;
  margin: 0.66667em 0; }

/**
   * Address styling not present in IE 8/9.
   */
mark {
  background: #ff0;
  color: #000; }

/**
* Address inconsistent and variable font size in all browsers.
*/
small {
  font-size: 80%; }

/**
* Prevent `sub` and `sup` affecting `line-height` in all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
   * Remove border when inside `a` element in IE 8/9/10.
   */
img {
  border: 0; }

/**
   * Correct overflow not hidden in IE 9/10/11.
   */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
   * Address margin not present in IE 8/9 and Safari.
   */
figure {
  margin: 1em 40px; }

/**
* Address differences between Firefox and other browsers.
*/
hr {
  box-sizing: content-box;
  height: 0; }

/**
* Contain overflow in all browsers.
*/
pre {
  overflow: auto; }

/**
* Address odd `em`-unit font size rendering in all browsers.
*/
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
* Known limitation: by default, Chrome and Safari on OS X allow very limited
* styling of `select`, unless a `border` property is set.
*/
/**
* 1. Correct color not being inherited.
*    Known issue: affects color of disabled elements.
* 2. Correct font properties not being inherited.
* 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
*/
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
   * Address `overflow` set to `hidden` in IE 8/9/10/11.
   */
button {
  overflow: visible; }

/**
* Address inconsistent `text-transform` inheritance for `button` and `select`.
* All other form control elements do not inherit `text-transform` values.
* Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
* Correct `select` style inheritance in Firefox.
*/
button,
select {
  text-transform: none; }

/**
* 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
*    and `video` controls.
* 2. Correct inability to style clickable `input` types in iOS.
* 3. Improve usability and consistency of cursor style between image-type
*    `input` and others.
*/
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
* Re-set default cursor for disabled elements.
*/
button[disabled],
html input[disabled] {
  cursor: default; }

/**
* Remove inner padding and border in Firefox 4+.
*/
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
* Address Firefox 4+ setting `line-height` on `input` using `!important` in
* the UA stylesheet.
*/
input {
  line-height: normal; }

/**
   * It's recommended that you don't attempt to style these elements.
   * Firefox's implementation doesn't respect box-sizing, padding, or width.
   *
   * 1. Address box sizing set to `content-box` in IE 8/9/10.
   * 2. Remove excess padding in IE 8/9/10.
   */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
* Fix the cursor style for Chrome's increment/decrement buttons. For certain
* `font-size` values of the `input`, it causes the cursor style of the
* decrement button to change from `default` to `text`.
*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

/**
* 1. Address `appearance` set to `searchfield` in Safari and Chrome.
* 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
*/
input[type='search'] {
  box-sizing: content-box; }

/**
* Remove inner padding and search cancel button in Safari and Chrome on OS X.
* Safari (but not Chrome) clips the cancel button when the search input has
* padding (and `textfield` appearance).
*/
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
* Define consistent border, margin, and padding.
*/
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
* 1. Correct `color` not being inherited in IE 8/9/10/11.
* 2. Remove padding so people aren't caught out if they zero out fieldsets.
*/
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
   * Remove default vertical scrollbar in IE 8/9/10/11.
   */
textarea {
  overflow: auto; }

/**
* Don't inherit the `font-weight` (applied by a rule above).
* NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
*/
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
* Remove most spacing between table cells.
*/
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.l-constrained:after, .front-preisanfrage h2:after, .front-preisanfrage .block__content:after, .simone-heller h2:after, .simone-heller .block__content:after, .l-footer .l-region--footer:after, .view-touren-in-sevilla:after,
.l-region--header:after,
.l-region--navigation:after,
.l-region--highlighted:after, .simone-heller:after, #block-views-touren-in-sevilla-block-1:after, #block-views-touren-in-sevilla-block:after {
  content: "";
  display: table;
  clear: both; }

.left {
  float: left;
  margin: 0 1em 1em 0; }

.right {
  float: right;
  margin: 0 0 1em 1em; }

.button, .form-submit,
.button-main, .button-white {
  display: inline-block;
  padding: .2em .5em;
  margin: 0 .5em .5em 0;
  text-align: center;
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
  font-family: 'Fjalla One', sans-serif;
  font-weight: 400;
  text-transform: uppercase; }
  .button:hover, .form-submit:hover,
  .button-main:hover, .button-white:hover {
    cursor: pointer;
    text-decoration: none; }
  .button:active, .form-submit:active,
  .button-main:active, .button-white:active {
    cursor: pointer; }

.form-submit {
  margin: .5em 0;
  display: block; }

.form-submit,
.button-main {
  border-color: #983851;
  color: #983851; }
  .form-submit:hover,
  .button-main:hover {
    border-color: #642435;
    color: #642435; }

.button-white {
  border-color: #eee;
  color: #eee; }
  .button-white:hover {
    border-color: #fff;
    color: #fff; }

fieldset.collapsible {
  position: relative;
  clear: both;
  margin-bottom: 1em; }

.grippie {
  display: none; }

.form-type-textfield {
  position: relative; }

.form-type-textfield label {
  position: absolute;
  top: .5em;
  left: 0;
  cursor: text; }

.form-type-textfield input[type="text"] {
  width: 90%;
  border-style: none none solid;
  border-color: #bbb;
  border-width: 1px;
  background-color: transparent;
  outline: none;
  padding: .2em .5em 0 0;
  height: 2.4em;
  line-height: 1.2; }

.form-textarea {
  width: 90%;
  border-style: solid;
  border-color: #bbb;
  border-width: 1px; }

.form-type-textfield.error {
  border: 1px solid red; }

.form-type-textfield input[type="text"]:focus {
  border-bottom: 1px solid #983851;
  box-shadow: 0px 1px 0px 0px #983851; }

.form-item label,
.form-actions label {
  display: block;
  font-weight: 300;
  line-height: 1;
  transition: all 0.2s ease-out 0s; }

.form-type-textfield.active > label {
  transform: translateY(-100%);
  color: #983851;
  font-size: .8em; }

.form-type-textfield > label,
.form-type-textarea > label,
.form-type-radios > label {
  color: #999;
  margin-bottom: .5em; }

.form-type-textfield > label.error,
.form-type-textarea > label.error,
.form-type-radios > label.error {
  color: red; }

.field-widget-options-buttons {
  width: 90%;
  border-bottom: 1px solid #bbb;
  margin-bottom: .5em; }

.form-item.form-type-radio {
  margin-bottom: 0; }
  .form-item.form-type-radio label {
    display: inline-block; }

.multipage-counter {
  float: right; }

.marker,
.form-required {
  color: #ed018c; }

@media (min-width: 44em) {
  .form-text,
  .form-textarea {
    max-width: 40em; } }

body {
  color: #454545;
  background-color: #fff; }

.l-constrained, .front-preisanfrage h2, .front-preisanfrage .block__content, .simone-heller h2, .simone-heller .block__content, .l-footer .l-region--footer, .view-touren-in-sevilla,
.l-region--header,
.l-region--navigation,
.l-region--highlighted {
  overflow: visible;
  width: 96%;
  max-width: 1200px;
  margin: 0 auto; }

.l-main {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto; }

.not-front .l-header {
  min-height: 300px; }

.not-front .l-main {
  position: relative;
  overflow: visible;
  background: #fff; }

@media (min-width: 44em) {
  .l-constrained, .front-preisanfrage h2, .front-preisanfrage .block__content, .simone-heller h2, .simone-heller .block__content, .l-footer .l-region--footer, .view-touren-in-sevilla,
  .l-region--header,
  .l-region--navigation,
  .l-region--highlighted {
    width: 90%; }
  .one-sidebar.sidebar-first .l-content {
    width: 74.57627%;
    float: right;
    margin-right: 0; }
  .one-sidebar.sidebar-first .l-region--sidebar-first {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
  .one-sidebar.sidebar-second .l-content {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
  .one-sidebar.sidebar-second .l-region--sidebar-second {
    width: 32.20339%;
    float: right;
    margin-right: 0; }
  .not-front .l-main {
    top: -100px;
    z-index: 0; }
    .not-front .l-main:before {
      position: absolute;
      z-index: -1;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.5));
      top: -1em;
      left: -1em;
      right: -1em;
      height: 200px;
      content: ''; } }

@media (min-width: 70em) {
  .l-constrained, .front-preisanfrage h2, .front-preisanfrage .block__content, .simone-heller h2, .simone-heller .block__content, .l-footer .l-region--footer, .view-touren-in-sevilla,
  .l-region--header,
  .l-region--navigation,
  .l-region--highlighted {
    width: 80%; }
  /* Sidebars */
  .two-sidebars .l-content {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .two-sidebars .l-region--sidebar-first {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
  .two-sidebars .l-region--sidebar-second {
    width: 23.72881%;
    float: right;
    margin-right: 0; } }

li {
  margin-bottom: .5em; }

.simple-list, .header-menu ul, #block-locale-language ul {
  margin: 0;
  padding: 0; }
  .simple-list li, .header-menu ul li, #block-locale-language ul li {
    list-style-image: none;
    list-style-type: none; }

img, media {
  max-width: 100%;
  height: auto !important;
  display: block; }

.file-icon {
  display: inline-block; }

table {
  border-bottom: 1px solid #ddd;
  margin-bottom: 2em; }
  table a {
    color: #642435; }

thead tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }

th {
  padding: .5em;
  background-color: transparent;
  text-align: left;
  vertical-align: top; }

td {
  padding: .5em;
  vertical-align: top; }

tr.odd {
  background-color: #fff; }

body {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  line-height: 1.5;
  color: #454545; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: 'Fjalla One', sans-serif;
  font-weight: 400;
  text-align: left;
  color: #642435; }

h1 {
  font-size: 1.8em;
  margin-bottom: 0; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.3em; }

h4, h5, h6 {
  font-size: 1.1em; }

p {
  margin: 0 0 .5em 0; }

strong {
  font-weight: 700; }

a {
  color: #732a3d;
  text-decoration: none; }
  a:hover {
    text-decoration: underline;
    color: #983851; }

.menu {
  list-style-type: none;
  list-style-image: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0; }
  .menu li,
  .menu li.leaf,
  .menu li.expanded,
  .menu li.collapsed {
    list-style-type: none;
    list-style-image: none; }

.breadcrumb {
  display: none;
  margin: 0;
  padding: 0;
  font-size: .8em; }
  .breadcrumb li {
    list-style-type: none;
    list-style-image: none;
    display: inline-block; }
    .breadcrumb li:after {
      content: ">>";
      display: inline-block;
      padding: 0 .2em; }
    .breadcrumb li:last-child:after {
      content: " "; }

@media (min-width: 44em) {
  .breadcrumb {
    display: block; } }

/**
 *  @file
 *  Main menu navigation styles
 */
/*Superfish 1 specific for the Superfish block 1 (Main Menu)*/
#block-superfish-1 {
  position: relative;
  margin-bottom: 1em;
  background: linear-gradient(to bottom, #fff, #ebebeb 70%);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
  #block-superfish-1 .block__content {
    display: table;
    width: 100%; }
  #block-superfish-1 a {
    color: #222; }
    #block-superfish-1 a:hover, #block-superfish-1 a.active {
      color: #732a3d;
      text-decoration: none; }

/*Superfish generic styles*/
.sf-menu {
  float: none;
  display: table-row;
  width: 100%;
  padding: 0; }

.sf-menu.sf-navbar {
  width: 100%; }

.sf-menu.sf-horizontal li.sf-depth-1 {
  float: none;
  display: table-cell;
  text-align: center;
  border-right: 1px solid #fff; }
  .sf-menu.sf-horizontal li.sf-depth-1:last-child {
    border-right: none; }

.sf-menu.sf-horizontal li.sf-depth-1 a {
  padding: .5em;
  border-left: 1px solid #444; }
  .sf-menu.sf-horizontal li.sf-depth-1 a:first-child {
    border-left: none; }
  .sf-menu.sf-horizontal li.sf-depth-1 a:hover {
    color: #983851; }
  .sf-menu.sf-horizontal li.sf-depth-1 a.active {
    color: #983851; }

.sf-menu.sf-horizontal li.sf-depth-2,
.sf-menu.sf-horizontal li.sf-depth-3 {
  text-align: left;
  background: #252525;
  border-bottom: 1px solid #444; }

.sf-menu.sf-horizontal li.sf-depth-2 a,
.sf-menu.sf-horizontal li.sf-depth-3 a {
  background: #fff;
  color: #333;
  font-size: .9em;
  padding: .7em 1em; }
  .sf-menu.sf-horizontal li.sf-depth-2 a:hover,
  .sf-menu.sf-horizontal li.sf-depth-3 a:hover {
    color: #642435; }

.sf-menu a,
.sf-menu a:visited,
.sf-menu span.nolink {
  margin: 1px 1px 0 0; }

.sf-menu span.sf-description {
  display: block;
  font-size: 0.833em;
  line-height: 1.5;
  margin: 5px 0 0 5px;
  padding: 0; }

.sf-menu li:hover,
.sf-menu li.sfHover,
.sf-menu a:focus,
.sf-menu a:hover,
.sf-menu a:active,
.sf-menu span.nolink:hover {
  outline: 0;
  color: #642435;
  text-decoration: none; }

.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
  left: 0;
  top: 2em; }

.sf-menu li li:hover > ul,
.sf-menu li li.sfHover > ul {
  left: 12em;
  top: 0; }

#superfish-1-accordion {
  background-color: #333; }
  #superfish-1-accordion li.sf-depth-1 {
    border-bottom: 2px solid #444; }
  #superfish-1-accordion a {
    background-color: #f1f1f1;
    color: #222;
    padding: .5em; }
    #superfish-1-accordion a:hover {
      background-color: #222;
      color: #fff; }
  #superfish-1-accordion a.sf-accordion-button {
    position: absolute;
    right: 0;
    top: 3px;
    font-size: .8em;
    z-index: 499;
    width: 6em;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    border-left: #888 1px solid; }
  #superfish-1-accordion li li a,
  #superfish-1-accordion li li span.nolink {
    padding-left: 2em;
    font-size: .9em; }
  #superfish-1-accordion li li li a,
  #superfish-1-accordion li li li span.nolink {
    padding-left: 3em; }

#block-superfish-1 .sf-accordion-toggle {
  margin-bottom: .5em; }
  #block-superfish-1 .sf-accordion-toggle > a {
    padding: 0.5em 1em;
    display: block;
    text-align: center;
    background-color: #f1f1f1;
    border-bottom: 2px solid #111;
    color: #222; }
    #block-superfish-1 .sf-accordion-toggle > a:hover {
      color: #fff;
      text-decoration: none;
      background-color: #333; }

.block--views-footer-menu-block,
.block--views-footer-menu-block-1,
.block--views-footer-menu-block-2 {
  text-align: left; }
  .block--views-footer-menu-block h2,
  .block--views-footer-menu-block-1 h2,
  .block--views-footer-menu-block-2 h2 {
    color: #d792a4;
    font-size: 1em;
    border-bottom: 1px solid rgba(215, 146, 164, 0.1);
    margin-bottom: .5em; }
  .block--views-footer-menu-block ul,
  .block--views-footer-menu-block-1 ul,
  .block--views-footer-menu-block-2 ul {
    list-style-type: none;
    list-style-image: none;
    margin: 0;
    padding: 0; }
    .block--views-footer-menu-block ul li,
    .block--views-footer-menu-block-1 ul li,
    .block--views-footer-menu-block-2 ul li {
      margin: 0;
      margin-bottom: .5em; }

@media (min-width: 30em) {
  .block--views-footer-menu-block {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .block--views-footer-menu-block-1 {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .block--views-footer-menu-block-2 {
    width: 32.20339%;
    float: right;
    margin-right: 0; } }

.block--entityform-block-presupuesto {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), white 25%);
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 1em 1.5em;
  font-size: .9em; }
  .block--entityform-block-presupuesto h2 {
    margin-bottom: 1em; }
  .block--entityform-block-presupuesto #edit-actions {
    float: left;
    margin-right: 1em; }
  .block--entityform-block-presupuesto .multipage-button {
    display: block;
    float: left; }

.front_teaser .block__content {
  color: #fff; }

@media (min-width: 44em) {
  .front_teaser {
    overflow: hidden; } }

.header-menu h2 {
  display: none; }

.header-menu ul {
  text-align: center; }

.header-menu li {
  display: inline-block;
  margin: 0 .5em; }

.header-menu a {
  font-family: 'Fjalla One', sans-serif;
  font-weight: 400;
  font-size: 1.1em;
  color: #eee; }
  .header-menu a:hover {
    color: #fff; }
  .header-menu a.active {
    color: #732a3d; }

@media (min-width: 44em) {
  .header-menu ul {
    text-align: right; }
  .header-menu li {
    margin: 0 0 0 1em; } }

#block-locale-language {
  padding-top: 1em; }
  #block-locale-language h2 {
    display: none; }
  #block-locale-language ul {
    text-align: center; }
  #block-locale-language li {
    display: inline-block;
    font-size: .9em;
    text-transform: lowercase; }
  #block-locale-language a {
    font-family: 'Fjalla One', sans-serif;
    font-weight: 400;
    color: #eee; }
    #block-locale-language a:hover {
      color: #fff; }
    #block-locale-language a.active {
      color: #732a3d; }

@media (min-width: 44em) {
  #block-locale-language ul {
    text-align: right; } }

.front-preisanfrage {
  padding: 2em 0; }
  .front-preisanfrage select {
    font-size: .8em; }

@media (min-width: 44em) {
  .front-preisanfrage select {
    font-size: .9em; } }

@media (min-width: 70em) {
  .front-preisanfrage select {
    font-size: 1em; } }

#block-search-form .form-item-search-block-form,
#block-search-form .form-actions {
  display: inline-block;
  vertical-align: top; }
  #block-search-form .form-item-search-block-form .form-submit,
  #block-search-form .form-actions .form-submit {
    margin: 0;
    background-color: transparent;
    border: 1px solid #ddd;
    padding: 0 .4em;
    box-shadow: none; }
    #block-search-form .form-item-search-block-form .form-submit:hover,
    #block-search-form .form-actions .form-submit:hover {
      border: 1px solid #fff; }
  #block-search-form .form-item-search-block-form .form-item,
  #block-search-form .form-actions .form-item {
    margin-bottom: 0; }
  #block-search-form .form-item-search-block-form .form-text,
  #block-search-form .form-actions .form-text {
    color: #444; }
  #block-search-form .form-item-search-block-form input,
  #block-search-form .form-actions input {
    height: 1.5em; }

@media (min-width: 70em) {
  #block-search-form {
    text-align: right; } }

.simone-heller {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.7), transparent 80%), url(../images/bg-simone-v1.jpg);
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  padding: 2em 0;
  text-align: right;
  color: #fff; }
  .simone-heller h2 {
    text-align: right;
    color: #fff;
    padding-left: 40%;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); }
  .simone-heller .block__content {
    padding-left: 40%; }
    .simone-heller .block__content p {
      font-size: 1.2em;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); }

@media (min-width: 44em) {
  .simone-heller {
    min-height: 400px; }
    .simone-heller h2 {
      margin-bottom: 1em; }
    .simone-heller .block__content {
      padding-left: 50%; }
      .simone-heller .block__content a {
        margin: 1em 0; } }

@media (min-width: 70em) {
  .simone-heller {
    min-height: 600px; }
    .simone-heller .block__content {
      padding-left: 50%; } }

#block-views-touren-in-sevilla-block-1 {
  padding: 2em 0;
  text-align: center; }

@media (min-width: 44em) {
  #block-views-touren-in-sevilla-block-1 {
    background-size: cover; }
    #block-views-touren-in-sevilla-block-1 .views-row {
      width: 44.44444%;
      float: left;
      margin-bottom: 2em; }
      #block-views-touren-in-sevilla-block-1 .views-row:nth-child(2n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      #block-views-touren-in-sevilla-block-1 .views-row:nth-child(2n + 2) {
        margin-left: 55.55556%;
        margin-right: -100%;
        clear: none; } }

#block-views-touren-in-sevilla-block {
  padding: 2em 0;
  text-align: center; }

@media (min-width: 44em) {
  #block-views-touren-in-sevilla-block {
    background: url(../images/bg-map-v01.jpg) center top transparent no-repeat;
    background-size: cover; }
    #block-views-touren-in-sevilla-block .views-row {
      width: 44.44444%;
      float: left;
      margin-bottom: 2em; }
      #block-views-touren-in-sevilla-block .views-row:nth-child(2n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      #block-views-touren-in-sevilla-block .views-row:nth-child(2n + 2) {
        margin-left: 55.55556%;
        margin-right: -100%;
        clear: none; } }

.front .l-content {
  padding: 2em 0 0; }
  .front .l-content h1 {
    text-align: center; }
  .front .l-content .node--page--full {
    text-align: center;
    padding: 2em 4em 0;
    background-image: url(../images/sh-bg-1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    .front .l-content .node--page--full p:last-child {
      margin-bottom: 0; }
    .front .l-content .node--page--full img {
      padding: 0; }

.node--article--teaser > img {
  float: left;
  margin: 0 1em 1em 0; }

.node--article--teaser .field-name-node-link {
  float: right;
  font-size: .9em;
  color: #983851; }

.node--article--full .field-name-field-image {
  margin-bottom: 2rem; }

.node--article--full .field-name-body {
  margin-bottom: 2rem; }

.node--article--full .field-name-field-bildergallerie {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .node--article--full .field-name-field-bildergallerie > a {
    display: inline-block;
    margin: 1rem;
    flex: none; }

@media (min-width: 44em) {
  .node--page--full img {
    padding: 5% 10%; } }

@media (min-width: 70em) {
  .node--page--full img {
    padding: 5% 15%; } }

.node--tour--full {
  position: relative; }
  .node--tour--full .group-side {
    position: absolute;
    left: 1em;
    top: 1em;
    right: 1em;
    height: auto;
    min-height: 200px;
    padding: .5em;
    color: #fff;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 100px);
    font-size: .9em;
    overflow: hidden; }
  .node--tour--full .field--name-field-art-der-tour {
    font-weight: bold;
    padding: 0 .5em .5em 0; }
  .node--tour--full .field--name-field-dauer {
    font-weight: bold;
    padding: 0 .5em .5em 0; }
  .node--tour--full .field--name-field-highlights {
    display: none; }
    .node--tour--full .field--name-field-highlights a {
      display: inline-block;
      margin-right: 1em;
      color: #fff;
      text-decoration: underline; }
      .node--tour--full .field--name-field-highlights a:hover {
        color: #ddd; }
  .node--tour--full .field-name-body {
    padding: 2em 0; }
  .node--tour--full .field--name-field-info-hinweise .field__item {
    display: list-item;
    list-style-position: inside;
    font-family: 'Fjalla One', sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    margin-bottom: 1em; }

@media (min-width: 44em) {
  .node--tour--full .group-side {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 200px); }
  .node--tour--full .field--name-field-highlights {
    display: block; } }

.label-inline {
  display: inline-block;
  font-weight: bold; }

.l-content {
  padding: 1em; }
  .l-content h1 {
    text-align: center; }

.node--full {
  padding: 1em; }
  .node--full .field-name-body a {
    text-decoration: underline; }

#preisanfrage-stadtf-hrung-entityform-edit-form #edit-field-tour-auswahl label, #tour-request-entityform-edit-form #edit-field-tour-auswahl label {
  margin-bottom: 1em; }

#preisanfrage-stadtf-hrung-entityform-edit-form .form-type-checkboxes:after, #tour-request-entityform-edit-form .form-type-checkboxes:after {
  content: "";
  display: table;
  clear: both; }

#preisanfrage-stadtf-hrung-entityform-edit-form .form-type-checkboxes > label, #tour-request-entityform-edit-form .form-type-checkboxes > label {
  color: #983851;
  margin-bottom: 1em; }

#preisanfrage-stadtf-hrung-entityform-edit-form .form-item.form-type-checkbox, #tour-request-entityform-edit-form .form-item.form-type-checkbox {
  position: relative;
  min-height: 2.2em; }
  #preisanfrage-stadtf-hrung-entityform-edit-form .form-item.form-type-checkbox:after, #tour-request-entityform-edit-form .form-item.form-type-checkbox:after {
    content: "";
    display: table;
    clear: both; }
  #preisanfrage-stadtf-hrung-entityform-edit-form .form-item.form-type-checkbox label, #tour-request-entityform-edit-form .form-item.form-type-checkbox label {
    vertical-align: middle;
    float: right;
    width: 90%; }
  #preisanfrage-stadtf-hrung-entityform-edit-form .form-item.form-type-checkbox input, #tour-request-entityform-edit-form .form-item.form-type-checkbox input {
    float: left;
    width: 10%; }

@media (min-width: 44em) {
  #preisanfrage-stadtf-hrung-entityform-edit-form .form-type-checkboxes:after, #tour-request-entityform-edit-form .form-type-checkboxes:after {
    content: "";
    display: table;
    clear: both; }
  #preisanfrage-stadtf-hrung-entityform-edit-form .form-item.form-type-checkbox, #tour-request-entityform-edit-form .form-item.form-type-checkbox {
    float: left;
    width: 50%; } }

.l-footer {
  background: radial-gradient(circle farthest-side at center center, #642435 0%, #511d2b 40%);
  color: #d7b5be;
  font-size: .9em;
  text-align: center;
  padding: 1em 0; }
  .l-footer a {
    color: #fff; }
    .l-footer a:hover, .l-footer a.active {
      color: #732a3d;
      text-decoration: none; }

.l-branding {
  text-align: center; }

.site-logo {
  display: block;
  padding-top: 1em; }

.site-logo img {
  max-width: 200px;
  margin: 0 auto;
  opacity: 1; }

.site-logo img:hover {
  opacity: .8; }

.l-region--navigation {
  clear: both; }

@media (min-width: 0) and (max-width: 43.99em) {
  .l-header {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent 70%), url(../images/bg_torredeoro.jpg);
    background-position: center top, center top;
    background-size: cover, cover;
    background-repeat: no-repeat, no-repeat; } }

@media (min-width: 44em) {
  .l-header {
    background-image: url(../images/bg_torredeoro.jpg);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat; }
  .site-logo {
    max-width: 30%;
    padding-top: 0;
    float: left;
    text-align: left; }
    .site-logo img {
      max-width: 220px;
      margin: .5em 1em .5em 0; }
  .l-region--branding {
    float: right;
    max-width: 70%; }
  .header-contact-info {
    float: right;
    clear: right;
    text-align: right;
    margin-bottom: .5em; }
    .header-contact-info a {
      padding-left: 1em; } }

@media (min-width: 70em) {
  .site-logo img {
    max-width: 240px;
    margin: 1em 1em .5em 0; } }

.l-region--highlighted {
  padding-top: 2em;
  padding-bottom: 2em; }
  .l-region--highlighted:after {
    content: "";
    display: table;
    clear: both; }

.front .l-region--highlighted-outer {
  min-height: 60vh; }

@media (min-width: 44em) {
  .front_teaser {
    width: 44.44444%;
    float: left;
    margin-right: 11.11111%; } }

.l-region--sidebar-first,
.l-region--sidebar-second {
  font-size: .8em; }

.l-region--sidebar-second .block {
  padding-bottom: 1em;
  margin-bottom: 1em; }
  .l-region--sidebar-second .block:after {
    content: "";
    display: table;
    clear: both; }

@media (min-width: 44em) {
  .l-region--sidebar-first {
    padding-top: 60px; } }

.view-comparar-productos table {
  font-size: .8em; }

.view-touren-in-sevilla .view-header h2 {
  text-align: center;
  color: #454545;
  font-size: 1.2em;
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  margin: 1em; }

.view-touren-in-sevilla .views-row {
  position: relative;
  margin-bottom: 2em; }
  .view-touren-in-sevilla .views-row a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; }
    .view-touren-in-sevilla .views-row a:hover {
      background: rgba(0, 0, 0, 0.2); }
  .view-touren-in-sevilla .views-row h2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 1em 4em .2em 1em;
    font-size: 1.2em;
    color: #eee;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8)); }
    .view-touren-in-sevilla .views-row h2:hover {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)); }
  .view-touren-in-sevilla .views-row .tour_info {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 20%;
    height: auto;
    text-align: right;
    padding: 0 0 .2em;
    font-size: 1em;
    color: #fff; }

.feed-icon {
  display: none; }

.standardview {
  margin-bottom: 2em; }
  .standardview .views-row {
    position: relative;
    margin-bottom: 1em;
    border-bottom: 1px solid #eee;
    padding-bottom: 1em;
    margin-bottom: 1em; }
    .standardview .views-row:after {
      content: "";
      display: table;
      clear: both; }
    .standardview .views-row > img {
      float: left;
      margin: 0 1em 1em 0; }
    .standardview .views-row > a {
      float: right;
      font-size: .9em; }
