table {
  border-bottom: 1px solid #ddd;
  margin-bottom: 2em;
  a {
    color: $color_main_dark; } }

thead tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }
th {
  padding: .5em;
  background-color: transparent;
  text-align: left;
  vertical-align: top; }
td {
  padding: .5em;
  vertical-align: top; }

tr.odd {
  background-color: #fff; }
