#block-locale-language {
  padding-top: 1em;
  h2 {
    display: none; }
  ul {
    @extend .simple-list;
    text-align: center; }
  li {
    display: inline-block;
    font-size: .9em;
    text-transform: lowercase; }
  a {
    font-family: 'Fjalla One', sans-serif;
    font-weight: 400;
    color: #eee;
    &:hover {
      color: #fff; }
    &.active {
      color: $link_main; } } }

@include breakpoint($tab) {
  #block-locale-language {
    ul {
      @extend .simple-list;
      text-align: right; }
    li {} } }

