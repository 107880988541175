.front .l-content {
  padding: 2em 0 0;
  h1 {
    text-align: center; }
  .node--page--full {
    text-align: center;
    padding: 2em 4em 0;
    background-image: url(../images/sh-bg-1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    p:last-child {
      margin-bottom: 0; }

    img {
      padding: 0; } } }
