body {
  color: $text_medium;
  background-color: #fff; }

.l-constrained,
.l-region--header,
.l-region--navigation,
.l-region--highlighted {
  @extend %clearfix;
  overflow: visible;
  width: 96%;
  max-width: 1200px;
  margin: 0 auto; }
.l-main {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto; }

.not-front .l-header {
  min-height: 300px; }

.not-front .l-main {
  position: relative;
  overflow: visible;
  background: #fff; }

@include breakpoint($tab) {
  .l-constrained,
  .l-region--header,
  .l-region--navigation,
  .l-region--highlighted {
    width: 90%; }
  .one-sidebar.sidebar-first {
    .l-content {
      @include span(9 of 12 last); }

    .l-region--sidebar-first {
      @include span(3 of 12 first); } }

  .one-sidebar.sidebar-second {
    .l-content {
      @include span(8 of 12 first); }

    .l-region--sidebar-second {
      @include span(4 of 12 last); } }

  .not-front .l-main {
    top: -100px;
    z-index: 0;

    &:before {
      position: absolute;
      z-index: -1;
      background: linear-gradient(to bottom, rgba(255, 255 , 255, 0.3), rgba(255, 255, 255, 0.5));
      top: -1em;
      left: -1em;
      right: -1em;
      height: 200px;
      content: ''; } } }

@include breakpoint($desk) {
  .l-constrained,
  .l-region--header,
  .l-region--navigation,
  .l-region--highlighted {
    width: 80%; }

  /* Sidebars */
  .two-sidebars {
    .l-content {
      @include span(6 of 12); }

    .l-region--sidebar-first {
      @include span(3 of 12 first); }

    .l-region--sidebar-second {
      @include span(3 of 12 last); } } }
