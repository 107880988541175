.l-region--highlighted {
  @include clearfix;
  padding-top: 2em;
  padding-bottom: 2em; }

.front .l-region--highlighted-outer {
  min-height: 60vh; }

@include breakpoint($tab) {
  .front_teaser {
    @include span(1 of 2 first); } }


