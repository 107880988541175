/**
 *  @file
 *  Main menu navigation styles
 */

/*Superfish 1 specific for the Superfish block 1 (Main Menu)*/
#block-superfish-1 {
  position: relative;
  margin-bottom: 1em;
  background: linear-gradient(to bottom , #fff, darken(#fff, 8%) 70%);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  .block__content {
    display: table;
    width: 100%; }

  a {
    color: $text_dark;
    &:hover,
    &.active {
      color: $link_main;
      text-decoration: none; } } }

/*Superfish generic styles*/

.sf-menu {
  float: none;
  display: table-row;
  width: 100%;
  padding: 0; }

.sf-menu.sf-navbar {
  width: 100%; }

.sf-menu.sf-horizontal li.sf-depth-1 {
  float: none;
  display: table-cell;
  text-align: center;
  border-right: 1px solid #fff;
  &:last-child {
    border-right: none; } }

.sf-menu.sf-horizontal li.sf-depth-1 a {
  padding: .5em;
  border-left: 1px solid #444;
  &:first-child {
    border-left: none; }
  &:hover {
    color: $link_main_hover; }
  &.active {
    color: $link_main_hover; } }

.sf-menu.sf-horizontal li.sf-depth-2,
.sf-menu.sf-horizontal li.sf-depth-3 {
  text-align: left;
  background: #252525;
  border-bottom: 1px solid #444; }

.sf-menu.sf-horizontal li.sf-depth-2 a,
.sf-menu.sf-horizontal li.sf-depth-3 a {
  background: #fff;
  color: #333;
  font-size: .9em;
  padding: .7em 1em;

  &:hover {
    color: $color_main_dark; } }


.sf-menu a,
.sf-menu a:visited,
.sf-menu span.nolink {
  margin: 1px 1px 0 0; }

.sf-menu a.sf-with-ul,
.sf-menu span.nolink.sf-with-ul {
 }  //padding-right: 6em

.sf-menu span.sf-description {
  display: block;
  font-size: 0.833em;
  line-height: 1.5;
  margin: 5px 0 0 5px;
  padding: 0; }

.sf-menu li:hover,
.sf-menu li.sfHover,
.sf-menu a:focus,
.sf-menu a:hover,
.sf-menu a:active,
.sf-menu span.nolink:hover {
  outline: 0;
  color: $color_main_dark;
  text-decoration: none; }

.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
  left: 0;
  top: 2em; }

.sf-menu li li:hover > ul,
.sf-menu li li.sfHover > ul {
  left: 12em;
  top: 0; }

#superfish-1-accordion {
  background-color: #333;
  li.sf-depth-1 {
    border-bottom: 2px solid #444; }
  a {
    background-color: #f1f1f1;
    color: #222;
    padding: .5em;
    &:hover {
      background-color: #222;
      color: #fff; } }

  a.sf-accordion-button {
    position: absolute;
    right: 0;
    top: 3px;
    font-size: .8em;
    z-index: 499;
    width: 6em;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    border-left: #888 1px solid; }

  li li a,
  li li span.nolink {
    padding-left: 2em;
    font-size: .9em; }

  li li li a,
  li li li span.nolink {
    padding-left: 3em; } }

#block-superfish-1 .sf-accordion-toggle {
  margin-bottom: .5em;
  > a {
    padding: 0.5em 1em;
    display: block;
    text-align: center;
    background-color: #f1f1f1;
    border-bottom: 2px solid #111;
    color: #222;
    &:hover {
      color: #fff;
      text-decoration: none;
      background-color: #333; } } }


