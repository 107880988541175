.l-footer {
  background: radial-gradient(circle farthest-side at center center , $color_main_dark 0%, darken($color_main_dark, 5%) 40%);
  color: $text_lightondark;
  font-size: .9em;
  text-align: center;
  padding: 1em 0;

  a {
    color: #fff;
    &:hover,
    &.active {
      color: $link_main;
      text-decoration: none; } }

  .l-region--footer {
    @extend .l-constrained; } }
