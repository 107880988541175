.button {
  display: inline-block;
  padding: .2em .5em;
  margin: 0 .5em .5em 0;
  text-align: center;
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
  font-family: 'Fjalla One', sans-serif;
  font-weight: 400;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    text-decoration: none; }

  &:active {
    cursor: pointer; } }

.form-submit {
  margin: .5em 0;
  display: block; }

.form-submit,
.button-main {
  @extend .button;
  border-color: $color_main;
  color: $color_main;
  &:hover {
    border-color: $color_main_dark;
    color: $color_main_dark; } }

.button-white {
  @extend .button;
  border-color: #eee;
  color: #eee;
  &:hover {
    border-color: #fff;
    color: #fff; } }
