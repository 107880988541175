.grippie {
  display: none; }

.form-type-textfield {
  position: relative; }

.form-type-textfield label {
  position: absolute;
  top: .5em;
  left: 0;
  cursor: text; }

.form-type-textfield input[type="text"] {
  width: 90%;
  border-style: none none solid;
  border-color: #bbb;
  border-width: 1px;
  background-color: transparent;
  outline: none;
  padding: .2em .5em 0 0;
  height: 2.4em;
  line-height: 1.2; }

.form-textarea {
  width: 90%;
  border-style: solid;
  border-color: #bbb;
  border-width: 1px; }

.form-type-textfield.error {
  border: 1px solid red; }

.form-type-textfield input[type="text"]:focus {
  border-bottom: 1px solid $color_main;
  box-shadow: 0px 1px 0px 0px $color_main; }

.form-item label,
.form-actions label {
  display: block;
  font-weight: 300;
  line-height: 1;
  transition: all 0.2s ease-out 0s; }

.form-type-textfield.active > label {
  transform: translateY(-100%);
  color: $color_main;
  font-size: .8em; }

.form-type-textfield > label,
.form-type-textarea > label,
.form-type-radios > label {
  color: #999;
  margin-bottom: .5em; }

.form-type-textfield > label.error,
.form-type-textarea > label.error,
.form-type-radios > label.error {
  color: red; }

.field-widget-options-buttons {
  width: 90%;
  border-bottom: 1px solid #bbb;
  margin-bottom: .5em; }

.form-item.form-type-radio {
  margin-bottom: 0;
  label {
    display: inline-block; } }

.multipage-counter {
  float: right; }

.marker,
.form-required {
  color: #ed018c; }

@include breakpoint($tab) {
  .form-text,
  .form-textarea {
    max-width: 40em; } }

