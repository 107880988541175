.block--entityform-block-presupuesto {
  box-shadow: 0 2px 1px rgba(0, 0, 0, .5);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.50), rgba(255, 255, 255, 1) 25%);
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 1em 1.5em;
  font-size: .9em;
  h2 {
    margin-bottom: 1em; }
  #edit-actions {
    float: left;
    margin-right: 1em; }
  .multipage-button {
    display: block;
    float: left; } }
