.block--views-footer-menu-block,
.block--views-footer-menu-block-1,
.block--views-footer-menu-block-2 {
  text-align: left;
  h2 {
    color: lighten($color_main,30%);
    font-size: 1em;
    border-bottom: 1px solid $footer_line;
    margin-bottom: .5em; }
  ul {
    @include no-bullets; } }

@include breakpoint($mobile_big) {
  .block--views-footer-menu-block {
    @include span(4 of 12 first); }
  .block--views-footer-menu-block-1 {
    @include span(4 of 12); }
  .block--views-footer-menu-block-2 {
    @include span(4 of 12 last); } }
