.menu {
  list-style-type: none;
  list-style-image: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  li,
  li.leaf,
  li.expanded,
  li.collapsed {
    list-style-type: none;
    list-style-image: none; } }


.breadcrumb {
  display: none;
  margin: 0;
  padding: 0;
  font-size: .8em;
  li {
    list-style-type: none;
    list-style-image: none;
    display: inline-block;
    &:after {
      content: ">>";
      display: inline-block;
      padding: 0 .2em; }
    &:last-child:after {
      content: " "; } } }

@include breakpoint($tab) {
  .breadcrumb {
    display: block; } }
