.simone-heller {
  background-image: linear-gradient(to left, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0) 80%), url(../images/bg-simone-v1.jpg);
  background-position:left top, left top {}
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  @extend %clearfix;
  padding: 2em 0;
  text-align: right;
  color: #fff;
  h2 {
    @extend .l-constrained;
    text-align: right;
    color: #fff;
    padding-left: 40%;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5); }
  .block__content {
    @extend .l-constrained;
    padding-left: 40%;
    p {
      font-size: 1.2em;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.5); } } }

@include breakpoint($tab) {
  .simone-heller {
    min-height: 400px;
    h2 {
      margin-bottom: 1em; }
    .block__content {
      padding-left: 50%;
      a {
        margin: 1em 0; } } } }

@include breakpoint($desk) {
  .simone-heller {
    min-height: 600px;
    .block__content {
      padding-left: 50%; } } }
