.header-menu {
  h2 {
    display: none; }
  ul {
    @extend .simple-list;
    text-align: center; }
  li {
    display: inline-block;
    //text-transform: uppercase
    margin: 0 .5em; }
  a {
    font-family: 'Fjalla One', sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    color: #eee;
    &:hover {
      color: #fff; }
    &.active {
      color: $link_main; } } }

@include breakpoint($tab) {
  .header-menu {
    ul {
      @extend .simple-list;
      text-align: right; }
    li {
      margin: 0 0 0 1em; } } }
