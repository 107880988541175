.front-preisanfrage {
  padding: 2em 0;
  h2 {
    @extend .l-constrained; }

  .block__content {
    @extend .l-constrained; }

  select {
    font-size: .8em; } }

@include breakpoint($tab) {
  .front-preisanfrage select {
    font-size: .9em; } }

@include breakpoint($desk) {
  .front-preisanfrage select {
    font-size: 1em; } }
