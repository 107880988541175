@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin no-bullets {
  list-style-type: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    margin-bottom: .5em; } }
