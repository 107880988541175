$color_main         : #983851;
$color_main_dark    : #642435;
$link_main          : darken($color_main, 10%);
$link_main_hover    : $color_main;
$text_dark          : #222;
$text_medium        : #454545;
$text_light         : #777;
$text_lightondark   : #d7b5be;
$border_light       : #eee;
$border_dark        : #444;
$bg_light           : #f3f3f3;
$bg_dark            : #252525;
$footer_line        : lighten(rgba($color_main, .1), 30%);
