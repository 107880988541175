#preisanfrage-stadtf-hrung-entityform-edit-form, #tour-request-entityform-edit-form {
  #edit-field-tour-auswahl label {
    //font-weight: bold
    margin-bottom: 1em; }
  .form-type-checkboxes {
    @include clearfix;
    > label {
      color: $color-main;
      margin-bottom: 1em; } }
  .form-item.form-type-checkbox {
    @include clearfix;
    position: relative;
    min-height: 2.2em;
    label {
      vertical-align: middle;
      float: right;
      width: 90%; }
    input {
      float: left;
      width: 10%; } } }

@include breakpoint($tab) {
  #preisanfrage-stadtf-hrung-entityform-edit-form, #tour-request-entityform-edit-form {
    .form-type-checkboxes {
      @include clearfix; }
    .form-item.form-type-checkbox {
      float: left;
      width: 50%; } } }
