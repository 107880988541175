.node--tour--full {
  position: relative;
  .group-side {
    position: absolute;
    left: 1em;
    top: 1em;
    right: 1em;
    height: auto;
    min-height: 200px;
    padding: .5em;
    color: #fff;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0,0,0,0) 100px);
    font-size: .9em;
    overflow: hidden; }

  .field--name-field-art-der-tour {
    font-weight: bold;
    padding: 0 .5em .5em 0; }

  .field--name-field-dauer {
    font-weight: bold;
    padding: 0 .5em .5em 0; }

  .field--name-field-highlights {
    display: none;
    a {
      display: inline-block;
      margin-right: 1em;
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #ddd; } } }

  .field-name-field-image {}

  .field-name-body {
    padding: 2em 0; }

  .field--name-field-info-hinweise {
    .field__items {}

    .field__item {
      display: list-item;
      list-style-position: inside;
      font-family: 'Fjalla One', sans-serif;
      font-weight: 400;
      font-size: 1.1em;
      margin-bottom: 1em; } } }

@include breakpoint($tab) {
  .node--tour--full {

    .group-side {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0,0,0,0) 200px); }

    .field--name-field-highlights {
      display: block; } } }
