.front_teaser {
  .block__content {
    color: #fff; } }

@include breakpoint($tab) {
  .front_teaser {
    overflow: hidden;
    .block__content {} } }


@include breakpoint($desk);
