.feed-icon {
  display: none; }

.standardview {
  margin-bottom: 2em;
  .views-row {
    @include clearfix;
    position: relative;
    margin-bottom: 1em;
    border-bottom: 1px solid $border_light;
    padding-bottom: 1em;
    margin-bottom: 1em;

    > img {
      float: left;
      margin: 0 1em 1em 0; }

    > a {
      float: right;
      font-size: .9em; } } }
