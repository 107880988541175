.l-branding {
  text-align: center; }

.site-logo {
  display: block;
  padding-top: 1em; }

.site-logo img {
  max-width: 200px;
  margin: 0 auto;
  opacity: 1; }

.site-logo img:hover {
  opacity: .8; }

.l-region--navigation {
  clear: both; }

@include breakpoint($below_tab) {
  .l-header {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0) 70%), url(../images/bg_torredeoro.jpg);
    background-position: center top, center top;
    background-size: cover, cover;
    background-repeat: no-repeat, no-repeat; } }

@include breakpoint($tab) {
  .l-header {
    background-image: url(../images/bg_torredeoro.jpg);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat; }
  .site-logo {
    max-width: 30%;
    padding-top: 0;
    float: left;
    text-align: left;

    img {
      max-width: 220px;
      margin: .5em 1em .5em 0; } }

  .l-region--branding {
    float: right;
    max-width: 70%; }

  .header-contact-info {
    float: right;
    clear: right;
    text-align: right;
    margin-bottom: .5em;
    a {
      padding-left: 1em; } } }

@include breakpoint($desk) {
  .site-logo {
    img {
      max-width: 240px;
      margin: 1em 1em .5em 0; } } }
