.view-touren-in-sevilla {
  @extend .l-constrained;
  .view-header {
    h2 {
      text-align: center;
      color: $text_medium;
      font-size: 1.2em;
      font-family: 'Roboto Slab', serif;
      font-weight: 300;
      margin: 1em; } }
  .views-row {
    position: relative;
    margin-bottom: 2em;

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        background: rgba(0, 0, 0, 0.2); } }

    h2 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      padding: 1em 4em .2em 1em;
      font-size: 1.2em;
      color: #eee;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8));
      &:hover {
        background: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, .9)); } }

    .tour_info {
      position: absolute;
      bottom: 0;
      right: 1em;
      width: 20%;
      height: auto;
      text-align: right;
      padding: 0 0 .2em;
      font-size: 1em;
      color: #fff; } } }
