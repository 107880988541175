%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

.left {
  float: left;
  margin: 0 1em 1em 0; }

.right {
  float: right;
  margin: 0 0 1em 1em; }
