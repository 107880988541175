.label-inline {
  display: inline-block;
  font-weight: bold; }

.l-content {
  padding: 1em;
  h1 {
    text-align: center; } }

.node--full {
  padding: 1em;
  .field-name-body {
    a {
      text-decoration: underline; } } }
